import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { Controls } from '@vidstack/react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from "@mui/material";
import { grey } from '@mui/material/colors';
import SideBarNav from "./SideBarNav";
import DownloadIcon from '@mui/icons-material/Download';

/* Motion imports */
import {motion, useInView, useAnimation} from 'framer-motion';
/* External components, scripts and styles */
import './executivewalk.css';

const Component = Controls.Root;



const ExecutiveWalk = (props) => {

    //Make sure the body is black
    document.body.style.backgroundColor = "black";


    const [showSideBar, setShowSideBar] = useState(false);

        /* Side Bar Navigation */
        const toggleSideBar = () => {
            
            if(showSideBar === true) {
                setShowSideBar(false);
            } else {
                setShowSideBar(true);
            }
        }
    const [searchParams, setSearchParams] = useSearchParams();

    const lowerCaseSearchParams = new URLSearchParams(
        Array.from(searchParams, ([key, value]) => [key.toLowerCase(), value])
    );
    let firstName = lowerCaseSearchParams.get("fname") ? lowerCaseSearchParams.get("fname") : "there";
    //Capitalize the first letter of person's name
    if(firstName !== 'there') {
            firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).split(" ")[0];
    }
    const baseFolder = "https://assets.barrowsconnectedstore.com/"
    const execwalkFolder = "execwalk/";
    const videoFolder = baseFolder + "video/cs/";
    const pdfFolder = baseFolder + "pdfs/";
    
    const leaveBehind = pdfFolder + "cs-8451-kroger-exec-walk-deck.pdf";
    const leaveBehindPoster = pdfFolder + "about-poster.png";
    const reportExample = pdfFolder + "cs-8451-kroger-example-report.pdf";
    const reportPoster = pdfFolder + "report-poster.png";
    const retailerSizzle = videoFolder + "multi-retailer-fly-through.mp4";
    const retailerSizzlePoster = videoFolder + "multi-retailer-flythrough-poster.png"
    const imageDirectory = "https://assets.barrowsconnectedstore.com/home/images/";
    const container = "flex w-[90%] max-w-[900px] h-[auto]  ";
    const slides = "flex w-[100%] h-[auto]  ";
    const slidesSection = "m-5 sm:m-6 md:m-8 lg:m-9 text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold justify-center ";
    const slidesTitle = "p-2 sm:p-3 md:p-4 lg:p-5 text-1xl sm:text-2xl md:text-3xl lg:text-4xl  ";
    const slidesFolder = baseFolder + "execwalk/";
    const box = "bg-blue-600 rounded-lg shadow-lg ";


    const exhibitShow = [{
        "key":1,
        "title":"Network Manager",
        "image":"tour-network-manager.png",
        "anchor":"network"
    },
    {
        "key":2,
        "title":"Network Manager - Uptime",
        "image":"tour-network-uptime.png"
    },
    {
        "key":3,
        "title":"Campaign Manager - Spot booking - List View",
        "image":"tour-bookings-list.png"
    },
    {
        "key":4,
        "title":"Campaign Manager - Spot booking - Timeline View",
        "image":"tour-bookings-timeline.png"
    },
    {
        "key":5,
        "title":"Campaign Manager - Creative Brief",
        "image":"tour-creative-brief.png"
    },
    {
        "key":6,
        "title":"Nestle Digital Campaign",
        "image":"nestle_summer_camapign.png"
    },
    {
        "key":7,
        "title":"Scaling The Network - STR Reporting",
        "image":"insights_revenue.png"
    },
    {
        "key":15,
        "title":"Sustainalytics",
        "image":"sustainalytics.png"
    },
    {
        "key":8,
        "title":"More Than A CMS",
        "image":"09.png"
    },
    {
        "key":9,
        "title":"AlcBev - Category Reinvention",
        "image":"10.png"
    },
    {
        "key":10,
        "title":"Evolution - Mason Meat Bunker",
        "image":"mason_bunker.png"
    },
    {
        "key":9,
        "title":"Evolution - Phase 2 Meat Bunker",
        "image":"cherry_bunker.png"
    },
    {
        "key":10,
        "title":"Evolution - Tesco POC, POE, Scale",
        "image":"23.png"
    },
    {
        "key":11,
        "title":"Evolution - Tesco POC, POE, Scale",
        "image":"24.png"
    },
    {
        "key":12,
        "title":"Evolution - Tesco POC, POE, Scale",
        "image":"25.png"
    },
    {
        "key":13,
        "title":"Evolution - Tesco POC, POE, Scale",
        "image":"26.png"
    },
    {
        "key":14,
        "title":"Ideation - Little Clinic Skip",
        "image":"little_clinic_skip.png"
    }
]
    const slideShow = [
        {
            "key":1,
            "image":"01.png",
            "anchor":"slides"
        },
        {
            "key":2,
            "image":"02.png"
        },
        {
            "key":3,
            "image":"03.png"
        },
        {
            "key":4,
            "image":"04.png"
        },
        {
            "key":5,
            "image":"05.png"
        },
        {
            "key":6,
            "image":"06.png"
        },
        {
            "key":7,
            "image":"07.png"
        },
        {
            "key":8,
            "image":"08.png"
        },
        {
            "key":9,
            "image":"09.png"
        },
        {
            "key":10,
            "image":"10.png"
        },{
            "key":11,
            "image":"11.png"
        },
        {
            "key":12,
            "image":"12.png"
        },
        {
            "key":13,
            "image":"13.png"
        },
        {
            "key":14,
            "image":"14.png"
        },
        {
            "key":15,
            "image":"15.png"
        },
        {
            "key":16,
            "image":"16.png"
        },
        {
            "key":17,
            "image":"17.png"
        },
        {
            "key":18,
            "image":"18.png"
        },
        {
            "key":19,
            "image":"19.png"
        },
        {
            "key":20,
            "image":"20.png"
        },
        {
            "key":21,
            "image":"21.png"
        },
        {
            "key":22,
            "image":"22.png"
        },
        {
            "key":23,
            "image":"23.png"
        },
        {
            "key":24,
            "image":"24.png"
        },
        {
            "key":25,
            "image":"25.png"
        },
        {
            "key":26,
            "image":"26.png"
        },
    ]
    const mainControls = useAnimation()
    const ref = useRef(null);
    const isInView = useInView(ref);

        useEffect(()=> {
            mainControls.start("visible");
        }, [isInView]);

    const BuildSlides = () => {
        return(
        slideShow.map((slideObj) =>(
            <div ref={ref} key={slideObj.key} id={slideObj.anchor ? slideObj.anchor : ""}>
                  {console.log(slidesFolder + slideObj.image)}
                <div className={slides}>
                    <img className="w-[auto] h-[auto] block" src={slidesFolder + slideObj.image} alt="Presentation slide"  />
                </div>
                  
            </div>
        ))
    )
    }
    const BuildExhibits = () => {
        
        return (
            exhibitShow.map((exhibitObj) =>(
                
                <div ref={ref} id={exhibitObj.anchor ? exhibitObj.anchor : ""} key={exhibitObj.key} className="w-full">
                
                        <div className={slides + slidesTitle }>
                            {exhibitObj.title} 
                        </div>    
                        <div className={slides + " min-w-full"}>
                            <img className="min-w-full h-[auto] block" src={slidesFolder + exhibitObj.image} alt={exhibitObj.image}  />
                        </div>
                  
                </div>
            ))
            
        )
    }
    return (
        <div id="PageContainer" className="
                flex 
                flex-col
                h-full
                items-center
                text-white">
                    <SideBarNav 
                        showSideBar={showSideBar}
                        closeSideBar={toggleSideBar} />
                    <div className="flex w-full bg-slate-800 h-[auto] p-0 md:pl-2 md:pt-2 md:h-[60px] sticky top-0 z-50">
                        <div className="md:p-1">
                            <IconButton onClick={()=> {toggleSideBar()}} variant="text" style={{color: grey[50]}}><MenuIcon className="menu-icon" /></IconButton>
                        </div>   
                        <div> 
                            <img className="pl-0 pt-4 max-w-[150px] md:max-w-[200px]" src={imageDirectory + "logo.png"} alt="Connected Store logo" />
                        </div>
                    </div>
                    <div className={container + " whitespace-nowrap w-[80%] "}>
                    <motion.p 
                        initial={{ y: "+100%", "opacity":0}}
                        animate={{ y: 0, "opacity":1}}
                        transition={{ 
                            delay: .25,
                            ease: "easeInOut" 

                        }} 
                        className="
                        font-bold 
                        w-[100%]
                        text-center
                        mt-5
                        text-5xl 
                        xs:text-6xl
                        sm:text-7xl 
                        md:text-8xl 
                        lg:text-9xl" style={{"overflow":"hidden", "position":"relative"}}>Hi, <span>{firstName}</span>!</motion.p>
                        
                    </div>

                    <div className={container + " whitespace-nowrap"}>
                     <motion.p 
                        initial={{ y: "+100%", "opacity":0}}
                        animate={{ y: 0, "opacity":1}}
                        transition={{ 
                            delay: .25,
                            ease: "easeInOut"
                        }}
                        className="
                            mt-3
                            text-2xl 
                            xs:text-4xl                            sm:text-7xl 
                            md:text-5xl 
                            lg:text-6xl
                        ">Welcome to <span className="text-blue-600"> CONNECTED<span className="font-bold">STORE</span></span></motion.p>
                     
                    </div>
                    <div className={container + "mt-5"}>
                        Why Partner With Barrows?
                    </div>
                    <div className={container + box + "mt-2"}>
                        <a href={leaveBehind} target="_blank">
                            <img src={leaveBehindPoster} className="rounded-lg p-1 border-2 border-blue-600 w-[auto] h-[auto] block" alt="Leave behind poster"></img>
                        </a>
                    </div>
                    <div className={container + "mt-3"}><a href={leaveBehind} target="_blank"><span className="text-sm text-white"><DownloadIcon></DownloadIcon>Click To Download</span></a></div>
                    <div className={container + "mt-10"}>
                        Retailer Examples
                    </div>
                    <div className={container + box}>
                     <MediaPlayer className="p-1" controls title="Retailer Fly Through Sizzle" poster={retailerSizzlePoster} src={retailerSizzle} webkit-playsinline="true">
                        <MediaProvider />
                    </MediaPlayer>
                    </div>
                   
                    <div className={container + "mt-10"}>
                        Example Report
                    </div>
                    <div className={container + box}>
                        <a href={reportExample} target="_blank">
                        <img src={reportPoster} className="rounded-lg p-1 border-2 border-slate-800 w-[auto] h-[auto] block" alt="Leave behind poster"></img> 
                        </a>
                    </div>
                    <div className={container + "mt-3"}><a href={reportExample} target="_blank"><span className="text-sm text-white"><DownloadIcon></DownloadIcon>Click To Download</span></a></div>
                    <div className={slides + slidesSection}>EXHIBITS</div>
                    {BuildExhibits()}
                    <div className={slides + slidesSection}>SLIDES</div>    
                    {BuildSlides()}
                        
                            
                        
                
        </div>
    )
}

export default ExecutiveWalk;


/*
 <div className={slides + slidesTitle}>
                        Network Manager
                    </div>    
                    <div className={slides}>
                        <img className="w-[auto] h-[auto] block" src={baseFolder + "/execwalk/tour-network-manager.png"} alt="Network Manager"  />
                    </div>
*/