import React, { useState, useEffect, useRef } from "react";
import {motion, AnimatePresence, useInView, useAnimation} from "framer-motion";
import { Error, Warning, ThumbUp, CheckCircle, AccessAlarm, NotificationsActive, Cancel } from "@mui/icons-material";
import Modal from '../../../utils/Modal';
import axios from 'axios';
import './krogerPOC.css';
import { v4 as uuid } from "uuid";

import { HashLink as Link } from 'react-router-hash-link';

const KrogerPOC = (props) => {
    
    const SIGNATURE = "Kroger POC Home Page :: v.1.0.2 :: "
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

               
    const lg = (message) =>{
        let log = true;
        if(log) {
            return message;
        }
    };

    const getUID = () => {
        return uuid();
    }
    const [hasLoggedSignature, setHasLoggedSignature] = useState(false);
   
    if(!hasLoggedSignature) {
        console.log(lg({"sig":SIGNATURE}));
        setHasLoggedSignature(true);
    }
    
    const API_DEV = "http://127.0.0.1:5000/";
    const API_PROD = "https://api.barrowsconnectedstore.com/"
    let API_URL = API_PROD;
    
    if(window.location.origin === 'http://localhost:3000') {
        API_URL = API_DEV;
    }

    //MODAL
    const [modalTitle, setModalTitle] = useState()
    const [modalContent, setModalContent] = useState();
    const [modalSRC, setModalSRC] = useState();
    const [modalShow, setShowModal] = useState(false);

    const pocModalId = "pocModal";

    const modalModal = {
        'cp-bakery.png': {
            'title': "DIGITAL DISPLAY",
            'subTitle': "SF",
            'body': <div>
                <p>The <span>DIGITAL DISPLAY</span> Small Format (SF) unit is a digital integrated display located on top of the cakes cooler in the Bakery.  It includes a 37” commercial-grade screen and unique RGB lighting. Sponsoring brands can include brands in the store that compliment bakery items.</p>
                <p>Brands can add co-op sponsorship messages to Kroger Bakery service offerings or purchase one or more spot for exclusive branding.</p>
            </div>,
            'features':['RGB Lighting', '37" Display'],
            'stores': ['Cherry Grove'],
            'departments':['Bakery']
        },
        'cp-end-cap.png': {
            'title': "END CAP",
            'subTitle': "LF",
            'body': <div>
                <p>The Connected Store <span>END CAP</span> Large Format (LF) is a digitally integrated aisle end display with a 50” commercial-grade screen, unique ambient lighting and/or RGB lighting, light-based content triggers, advanced analytics, and custom cladding that matches the existing shelves.</p>
                <p>Brands can purchase one or more of the five available spots. They can also recommend SKUs to merchandise on the shelf during their campaign period.</p>
            </div>,
            'features':['Advanced Analytics', 'Ambient Lighting', 'RGB Lighting', 'Custom Cladding', '50" Display'],
            'stores': ['Cherry Grove', 'Mason Montgomery'],
            'departments': ['Home Care,', 'Grocery']
        },
        'cp-entrance.png': {
            'title':'BILLBOARD',
            'subTitle': 'LF',
            'body': <div>
                <p>The <span>BILLBOARD</span> Large Format (LF) is a digitally integrated welcome screen with multiple 55” commercial-grade screens, unique RGB lighthing, custom cladding, and advanced analytics.  It is wall mounted at the front of the store facing traffic for maximum audience exposure.</p> 
                <p>Brands can purchase one or more of the five available spots.</p>
                <p className="modal-small-title">Billboard Large Format (LF) in trade</p>
                <p><img src="https://assets.barrowsconnectedstore.com/home/images/gallery-5.png" alt="Billboard In Trade" /></p>
                <div className="w-[100%] min-w-[100px] backgroundSize" style={{'backgroundSize':'cover', 'backgroundRepeat':'no-repeat', 'width':'100%', 'backgroundImage':"url('https://assets.barrowsconnectedstore.com/images/connection-points/goal-post-2.jpeg')"}}></div>
            </div>,
            'features':['Advanced Analytics', 'RGB Lighting', 'Wall Mounted', 'Entry Facing', 'Multiple 55" Displays'],
            'stores': ['Cherry Grove', 'Mason Montgomery'],
            'departments': ['Entrance']
        },
        'cp-haba-end-cap-small.png': {
            'title': 'END CAP',
            'subTitle': "SF",
            'body': 
            <div>
                <p>The Connected Store <span>END CAP</span> Small Format (SF) is a digitally integrated aisle end display with a 32” commercial-grade screen, unique RGB lighting, light-based content triggers, advanced analytics, and custom cladding that matches the existing shelves. It also has a glorification shelf for additional product merchandising.</p>
                <p>Brands can purchase one or more of the five available spots. They can also recommend SKUs to merchandise on the shelf during their campaign period.</p>
            </div>,
            'features':['Advanced Analytics', 'RGB Lighting', 'Glorification Shelf', '32" Display', 'Custom Cladding'],
            'stores':['Mason Montgomery'],
            'departments':['Haba']
        },
        'cp-haba-end-cap.png': {
            'title': 'END CAP',
            'subTitle': "LF",
            'body':  <div>
            <p>The Connected Store <span>END CAP</span> Large Format (LF) is a digitally integrated aisle end display with a 50” commercial-grade screen, unique ambient lighting and/or RGB lighting, light-based content triggers, advanced analytics, and custom cladding that matches the existing shelves.</p>
            <p>Brands can purchase one or more of the five available spots. They can also recommend SKUs to merchandise on the shelf during their campaign period.</p>
        </div>,
            'features':['RGB Lighting', '37" Display'],
            'stores': ['Cherry Grove'],
            'departments': ['Haba']
        }, 
        'cp-meat-bunker-cherry.png': {
            'title': 'BUNKER HEADER',
            'subTitle': 'SF',
            'body': <div>
                <p>The <span>BUNKER HEADER</span> Small Format (SF) is a digitally integrated display with a 37” commercial-grade screen and RGB lighting that changes to support the displayed brand. The display is attached to select bunkers in the Meat department, providing a cross-merchandising opportunity for brands.</p>
                <p>Brands can purchase one or more of the five available spots. They can also recommend products for cross-merchandising.</p>
                <p className="modal-small-title">Bunker Header Small Format (SF) in trade</p>
                <p><img src="https://assets.barrowsconnectedstore.com/home/images/gallery-7.png" alt="Bunker Header In Trade" /></p>
            </div>,
            'features':['RGB Lighting', 'Cross Merchandising', '37" Display'],
            'stores': ['Cherry Grove'],
            'departments':['Meat']
        }, 
        'cp-meat-bunker-mason.png': {
            'title': "BUNKER HEADER",
            'subTitle': "LF",
            'body': <div>
                <p>The <span>BUNKER HEADER</span> Large Format (LF) is a digitally integrated display with two 30” commercial-grade screens and RGB lighting that changes to support the displayed brand. The display is attached to select bunkers in the Meat and Produce departments, providing a cross-merchandising opportunity for brands.</p>
                <p>Brands can purchase one or more of the five available spots. They can also recommend products for cross-merchandising.</p>
                <p className="modal-small-title">Bunker Header Large Format (SF) in trade</p>
                <p><img src="https://assets.barrowsconnectedstore.com/home/images/gallery-4.png" alt="Bunker Header In Trade" /></p>
            </div>,
            'features':['RGB Lighting', 'Cross Merchandising', '2x30" Displays'],
            'stores': ['Mason Montgomery'],
            'departments': ['Meat']
        },
        'cp-pallet-end-cap.png': {
            'title': 'END CAP',
            'subTitle': 'P',
            'body': <div>
                <p>The <span>PALLET</span> (End Cap) is a digitally integrated aisle end display with a 75” commercial-grade screen, an area for bulk pack merchandising, and advanced analytics.</p>
                <p>Brands can purchase one or more of the five available spots. They can also recommend packs to merchandise during their campaign period.</p>
                <p className="modal-small-title">End Cap with pallet (P) feature in trade</p>
                <p><img src="https://assets.barrowsconnectedstore.com/home/images/gallery-3.png" alt="Pallet End Cap In Trade" /></p>
            </div>,
            'features':['Advanced Analytics', 'Bulk Pack', 'Easy Pallet Access', '75" Display'],
            'stores':['Cherry Grove', "Mason Montgomery"],
            'departments': ["Grocery"]
        },
        'cp-produce-bunker.png': {
            'title': "BUNKER HEADER",
            'subTitle': "LF",
            'body': <div>
                <p>The <span>BUNKER HEADER</span> Large Format (LF) is a digitally integrated display with two 30” commercial-grade screens and RGB lighting that changes to support the displayed brand. The display is attached to select bunkers in the Meat and Produce departments, providing a cross-merchandising opportunity for brands.</p>
                <p>Brands can purchase one or more of the five available spots. They can also recommend products for cross-merchandising.</p>
                <p className="modal-small-title">Bunker Header Large Format (LF) in trade</p>
                <p><img src="https://assets.barrowsconnectedstore.com/images/connection-points/bunker-header-3.jpeg" alt="Bunker Header In Trade" /></p>
            </div>,
            'features':['RGB Lighting', 'Cross Merchandising', '2x30" Displays'],
            'stores':['Cherry Grove', 'Mason Montgomery'],
            'departments':['Produce']
        },
        'cp-rx-waiting.png': {
            'title': "DIGITAL DISPLAY",
            'subTitle': "FS",
            'body': <div>
                <div>The <span>PROMO</span> (Screens) are a set of screnes at multiple locations in both Kroger stores. Screen sizes will vary.  <br/><br/><span>Here are the proposed screen locations:</span> <br/><br />
                    <ul style={{"listStyleType": "circle", "listStylePosition":'inside'}}>
                        <li><b>Little Clinic</b></li>
                        <li><b>Bakery</b></li>
                        <li><b>Deli</b></li>
                        <li><b>Pharmacy Waiting Room</b></li>
                        <li><b>Pharmacy Queue</b></li>
                    </ul>
                </div>
                <p>Brands can purchase one or more of the five available spots. They can also recommend products for cross-merchandising.</p>
            </div>,
            'features':['RGB Lighting', 'Cross Merchandising', '2x30" Displays'],
            'stores':['Cherry Grove', 'Mason Montgomery'],
            'departments':['Produce']
        },
        'cp-seasonal-hanging.png': {
            'title': "BILLBOARD",
            'subTitle': 'LF',
            'body': <div>
                <p>The <span>OVERHEAD</span> (Seasonal) is a digitally integrated hanging display with either a 98” commercial-grade screen. Each unit also has unique RGB lighting that changes to support the displayed brand, along with a custom-designed frame highly visible over the seasonal department.</p>
                <p>Brands can purchase one or more of the five available spots.</p>
            </div>,
            'features':['RGB Lighting', 'Free Standing', '98" Display'],
            'stores':['Cherry Grove', 'Mason Montgomery'],
            'departments':['Seasonal']
        },
        'cp-standee.png': {
            'title': "STANDEE",
            'subTitle': 'FS',
            'body': <div>
                <p>The <span>STANDEE</span> is a digitally integrated freestanding display with either a 43” or 46” commercial-grade screen. Each unit also has unique RGB lighting that changes to support the displayed brand, along with a custom-designed frame that can be easily positioned to ensure maximum exposure.</p>
                <p>Brands can purchase one or more of the five available spots.</p>
            </div>,
            'features':['RGB Lighting', 'Free Standing', '43" & 46" Displays'],
            'stores':['Cherry Grove', 'Mason Montgomery'],
            'departments':['Entrance,', 'Pharma']
        },
        'cp-wine-end-cap.png': {
            'title': 'END CAP',
            'subTitle': 'FS',
            'body': <div>
               <p>The <span>WINE</span> (End Cap) is a digitally integrated aisle end display with a 43” commercial-grade screen, unique ambient lighting, a scan-to-learn feature, and advanced analytics. It is a fully free-standing display with custom features to beautifully merchandise the participating brands.</p>
               <p>Brands can purchase one or more of the five available spots. They can also recommend SKUs to merchandise on the shelf during their campaign period.</p>
            </div>,
            'features':['Advanced Analytics', "Scan To Learn", "Free Standing", '43" Display'],
            'stores':['Mason Montgomery'],
            'departments':['AlcBev']
        }
    }


    const showModal = (img) => {
        let basePath = "https://assets.barrowsconnectedstore.com/retailers/kroger/";
        //setModalSRC(basePath + img);
        setModalContent(
            <div className="flex flex-col lg:flex-row h-[100%]">
                
                <div className="md:flex-col lg:w-[50%] lg:p-10">
                    <div className="flex flex-row ">
                        <div className="text-2xl sm:text-3xl text-extraBold  lg:text-4xl ">{modalModal[img]['title']}&nbsp;</div>
                        <div className=" text-2xl sm:text-3xl font-thin lg:text-4xl">({modalModal[img]['subTitle']})</div>
                       
                    </div>
                    <div className="text-sm modal-body">
                        <hr className=" h-px my-2 lg:my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                        {modalModal[img]['body']}              
                    </div>
                   
                    
                    <div className="mt-5 flex flex-wrap">
                        {
                            modalModal[img]['features'].map((item) => {
                                return(
                                    <div key={item} className='mr-2 mb-2 flex-col items-center bg-blue-700 rounded-full text-sm text-light-100'>
                                        <div className='p-2'>{item}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex mt-5 text-gray-500">
                        <div className="flex-col mb-5 "><span className="font-bold">Departments:</span> [</div>
                        {
                            modalModal[img]['departments'].map((item) => {
                                return(
                                  
                                        <div key={item} className='flex-col'>{item} {modalModal[img]['departments'].lenght } {modalModal[img]['departments'].lenght > 1 ? "," : null}</div>
                               
                                )
                            })
                        }
                        ]
                    </div>
                </div>
                <div className="md:flex-col lg:w-[50%] p-5 md:p-10 ">
                    <div key={getUID()} className="rounded-md mb-5">
                        <img className="rounded-md"  src={basePath + 'lg-' + img} alt={basePath + 'lg-' + img} />
                    </div>
                     <div className="flex flex-wrap">
                        {
                            modalModal[img]['stores'].map((item) => {
                                return(
                                    <div key={getUID()} className='mr-2 mb-2 flex-col items-center bg-green-500 min-w-[150px] text-center rounded-full text-sm text-light-100'>
                                        <div key={item} className='p-2'>{item}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                
            </div>
        )
        setShowModal(true);
    
       // let modal = document.querySelector("#"+pocModalId);
       //     modal.classList.remove("hidden");
    }

    const closeModal = () => {
        setShowModal(false);
    }
    //END MODAL 

    //HOOKS
    const SEND_MAIL = "send-mail";
    const SEND_SMS = "send-sms"
    //Toaster
    const [toasterShow, setToasterShow] = useState(false);
    const [toasterColor, setToasterColor] = useState("bg-green-500");
    const [toasterText, setToasterText] = useState("");
    const [toasterIcon, setToasterIcon] = useState("checkCircle");

    //API hooks
    const API = (hook, payload) => {
        //let toasterWrapper = document.querySelector("#toasterWrapper");
        //let toaster = document.querySelector("#toasterComponent");
        switch(hook) {
            case SEND_SMS:
               /* axios.post(API_URL + SEND_SMS, {"to":"+17272537565","message":"A request for Kroger POC information has been emailed."})
                .then(res => {
                    const api_response_object = res.data;
                })*/
                console.log("SEND SMS NOT YET AVAILABLE");
                break;
            case SEND_MAIL:
                    axios.post(API_URL + SEND_MAIL, payload)
                    .then(res => {
                        const api_response_object = res.data;
                        //console.log(lg({"sig":SIGNATURE, "function":"API-SEND_MAIL", "endpoint": API_URL, "response_object":JSON.stringify(api_response_object)}));
                        
                        switch(Number(api_response_object.status_code)) {
                            case 202:
                                    //setToasterColor("bg-green-600");
                                    //toaster.innerHTML = "Your inquiry was successfully sent."
                                    
                                    setToasterText("Your email was successfully sent.");
                                    setToasterColor("bg-green-600");
                                    setToasterIcon("thumbUp");
                                    setToasterShow(true);
                    
                                break;
                            case 500:
                                    //setToasterColor("bg-red-600");
                                    //toaster.innerHTML = "Your inquiry failed.  Please try again."
                                    setToasterText("Your inquiry failed.  Please try again.");
                                    setToasterColor("bg-red-600");
                                    setToasterIcon("error");
                                    setToasterShow(true);
                                break;
                            default:
                                console.error(lg({"sig":SIGNATURE, "function":"API-SEND_MAIL", "message":"Status code missing from API response"}));
                        
                        }
                        
                        //Animate the toaster in
                        //toasterWrapper.classList.remove("toaster-out");
                        //toasterWrapper.classList.add("toaster-in");
                        //setTimeout(()=>{toasterWrapper.style.opacity = 1}, 2000);

                    })
                break;
            default:
                console.error("No hook passed in API call");
        }   
    }

    //Form data
    const [formData, setFormData] = useState({
        firstName:{
            value:"",
            required:true
        },
        lastName: {
            value:"",
            required:true
        },
        email:{
            value:"",
            required:true
        },
        region:{
            value:"",
            required:false
        },
        brand: {
            value:"",
            required: true
        },
        industry:{
            value:"",
            required:false
        },
        phone: {
            value:"",
            required:false
        },
        message:{
            value:"",
            required:false
        }
    })
   
    //Form validation
    const validateEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(email)
    };

    const validateForm = (event) => {

        //console.log(lg({"sig":SIGNATURE, "function":"validateForm", "payload":event}));

        let valid = false;
        let elm = document.querySelector("#"+event.target.id);
        let elmRequired = event.target.required;
        let messageId = event.target.id + "Message";
        let elmMessage = document.querySelector("#"+messageId);
        
        try {
                //Make sure required values are not empty
                if(event.target.value === '' && elmRequired) {
                            
                    elmMessage.innerHTML = "* This is a required field";
                    elm.classList.add('form-error-highlight');
                    elm.focus();

                } else {

                    //Need to further validate email
                    if(event.target.id === 'email') {
                    
                        if(validateEmail(event.target.value)) {
                            try {
                                elmMessage.innerHTML = "";
                                elm.classList.remove('form-error-highlight');
                            } catch(e) {
                                //console.log(e)
                            }
                            formData.email.value = event.target.value;
                            valid = true;

                        } else {

                            elmMessage.innerHTML = "* Make sure you add a valid email";
                            elm.classList.add('form-error-highlight');
                            elm.focus();

                            
                        }

                    } else {
                        //If not empty make sure the highlight is cleared
                        elmMessage.innerHTML = "";
                        elm.classList.remove('form-error-highlight');
                        valid = true;
                    }
                
                }
                    let enableSubmitButton = true;
                    for (const [ky, val] of Object.entries(formData)) {
                        //console.log(lg({"sig":SIGNATURE, "function":"validateForm", "objectEntries":`${ky}: ${val.value} - ${val.required}`}));
                        //We only store the value if the value is valid.  So, we just need to check if all required fields are populate
                        if(val.value === '' && val.required === true) {
                           // console.log(ky + " was required and not populated")
                            enableSubmitButton = false;
                        }
                    }
                    if(enableSubmitButton) {
                        //console.log("ALL CLEAR!!!!")
                        document.querySelector("#submitButton").disabled = false;
                    }

        } catch(e) {
            console.error(lg({"sig":SIGNATURE, "function":"validateForm", "error":e}));
            
        }
       
        return {"valid": valid, "id":event.target.id, "value": event.target.value};

    }
   
    const updateForm = (event) => {
        
        //console.log(lg({"sig":SIGNATURE, "function":"updateForm", "payload":event}));
        
        let validateObj = validateForm(event);
        if(validateObj.valid) {
            let tmpData = formData;
            tmpData[event.target.id]["value"] = event.target.value;
            setFormData(tmpData);
            
            //console.log(lg({"sig":SIGNATURE, "function":"formData", "payload": JSON.stringify(formData)}));
        }
        
        //See if all the required fields are populated
       
    }

    const submitForm = (event) => {
        
        event.preventDefault();
        

        //let emailTemplate = `<p><b>Sender:</b> ${formData.firstName.value} ${formData.lastName.value} </p><p><b>Email:</b> <a mailto:="${formData.email.value}"/>${formData.email.value}</p><p><b>Brand/Service Name: </b> ${formData.brand.value} <p><p><b>Phone: </b> ${formData.phone.value} <p><b>Message: </b> ${formData.message.value}</p>`

        let payload = {};
            //payload.message = emailTemplate;
            //payload.to = ["tim.bagwell@barrowsglobal.com", "kurt.hold@barrowsglobal.com", "tai.higgo@barrowsglobal.com"];
            //payload.subject = "Kroger POC Inquiry";

            payload.template = "krogerpoc";
            payload.firstName = formData.firstName.value;
            payload.lastName = formData.lastName.value;
            payload.email = formData.email.value;
            payload.brand = formData.brand.value;
            payload.phone = formData.phone.value;
            payload.message = formData.message.value;
            
            API(SEND_MAIL, payload);
            if(API_DEV) {
                //Just testing this for now. 
                API(SEND_SMS);
            }
            
            document.querySelector("#contactForm").reset()

    }
    
    //If you are in dev mode, pop toaster to notify developer.
    useEffect(() => {
        if(API_URL === API_DEV) {
            setToasterText("Caution! You are using the DEV API");
            setToasterIcon("warning")
            setToasterColor("bg-yellow-500");
            setToasterShow(true);
        }
    }, [])
   
    const getToasterIcon = () => {
        switch(toasterIcon) {
            case "error":
                    return (<Error />);
                break;
            case "warning":
                    return (<Warning />);
                break;
            case "checkCircle":
                    return (<CheckCircle />);
                break;
            case "thumbsUp":
                    return (<ThumbUp />);
                break;
            case "notification":
                    return (<NotificationsActive />);
                break;
            default:
                return;
        }
    }

    const closeToaster = () => {
        setToasterShow(false);
    }

   
   
    return(
        <div className="kroger-page-wrapper">
            <Modal 
                frameCSS={"shadow-lg rounded-md bg-white p-5 w-[90vw] h-[90vh] slide-from-bottom-1k"} 
                closeCSSClass="slide-to-bottom-1k"
                title={modalTitle}
                content={modalContent}
                callBack={closeModal}
                show={modalShow}
                id={pocModalId}
                src={modalSRC}  
            />
           
            <AnimatePresence>
                {toasterShow && <motion.div id="krogerPOCToaster" onClick={()=>{closeToaster()}} className={toasterColor + " absolute z-50 mx-auto inset-0 w-fit h-fit text-sm text-white rounded-md shadow-lg cursor-pointer"} role="alert"
                    initial = {{
                        y: -200,
                        opacity: 0
                    }}
                    animate = {{
                        y:10,
                        opacity:1
                    }}
                    exit = {{
                        y: -200,
                        opacity: 0
                    }}
                >
                    
                    <div className="flex p-3">
                    <p id="toasterComponent">{getToasterIcon()}
                        &nbsp; {toasterText}</p>
                    <div className="ml-auto">
                        <button type="button" className="ml-4 inline-flex flex-shrink-0 justify-center items-center rounded-md text-white/[.5] hover:text-white focus:outline-none ">
                        <span className="sr-only">Close</span>
                        <Cancel />
                        </button>
                    </div>
                    </div>
                </motion.div>}
            </AnimatePresence>
            
            {/* HEADER SECTION */}
            <AnimatePresence>
                <motion.div
                    initial = {{
                        y: -100,
                        opacity: 0
                    }}
                    animate = {{
                        y:0,
                        duration: 4,
                        opacity:1
                    }}
                    transition = {{
                        type: "spring",
                        duration: .75
                    }}
                    
                >
                    <div className="bg-blue-300 sm:bg-blue-400 md:bg-blue-500 lg:bg-blue-600 xl:bg-blue-700 h-[5px] w-[100vw]"></div>
                    <div className="w-full shadow-md p-2 h-[80px] ">
                        <img src="https://assets.barrowsconnectedstore.com/logos/kroger-blue-cart.png" alt="Nature" className="w-[100%] h-[auto] max-w-[150px]"></img>
                        <img src="https://assets.barrowsconnectedstore.com/logos/cs-kroger-poc-logo.png" alt="Nature" className="w-[120px] h-[auto] sm:w-[150px] absolute right-6 top-9"></img>
                    </div>
                </motion.div>
            </AnimatePresence>
            {/* END HEADER SECTION */}

            {/* FORM SECTION */}
            <div className="p-4 mt-3 ml-3 md:mt-6 font-thin text-4xl text-gray-400 ">CONNECTED POC</div>
           
            <div className="sm:flex w-full p-4 ">
                <div className="sm:w-[100%] m-3 p-4 relative  sm:m-2 md:m-3 bg-[url('https://assets.barrowsconnectedstore.com/logos/barrows-b-white-mini-10plb-40.png')] bg-no-repeat bg-left-bottom bg-blue-700 shadow-md rounded-lg text-white">
                    <div className="p-4 font-light text-4xl sm:text-4xl md:text-5xl lg:text-7xl xlg:text-9xl">
                        IN-STORE <br/> <span className="font-black">DIGITAL MEDIA</span> AND <span className="font-black">MERCHANDISING</span> PLATFORM
                    </div>
                    <div className="mb-4 text-sm leading-8 pt-2 pb-10 p-4 sm:text-base sm:leading-8 sm:pt-6 xlg:text-lg xlg:leading-10">In partnership with Barrows, Kroger is conducting a six-month, two-store test to evaluate the efficacy of in-store digital media and merchandising. Throughout this Proof of Concept (POC), Barrows will directly engage with Kroger’s supplier brands to align on campaign timeline and strategy. Once planned, Barrows will manage all aspects of execution including content design, campaign flighting, and effectiveness reporting.
                    </div>
                    <div>
                        <p className="hidden font-thin text-3xl 2xl:text-5xl xl:text-4xl pb-0 mb-0"><span className="font-bold">Welcome ... </span> <span className="font-thin">to the Kroger<br/> CONNECTED</span><span className="text-blue-100 font-bold">STORE</span></p>
                        <div className="absolute bottom-2 right-4 text-slate-100 text-sm">Powered By Barrows <Link className="text-xs" to="../"><u>LEARN MORE</u></Link></div>
                    </div>
                </div>
                <div className="sm:w-[100%] m-3 p-4  bg-white  sm:m-2 shadow-md rounded-lg">
                    <p className="text-blue-700 text-3xl mt-3">Let's Get Started <span className="text-black text-xs">* Required</span></p>
                    
                    <form id="contactForm"  onSubmit={submitForm}>
                        <div className="md:flex items-center mt-12">
                            <div className="w-full md:w-1/2 flex flex-col">
                                <label className="font-semibold leading-none ">* First Name <span id="firstNameMessage" className="form-error-message"></span></label>
                                <input type="text" id="firstName" required onBlur={updateForm} onChange={updateForm} className={" leading-none p-3 border-2 mt-4 rounded focus:ring-0 focus:outline-0 focus:border-1"} />
                            </div>
                            <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                                <label className="font-semibold leading-none ">* Last Name <span id="lastNameMessage" className="form-error-message"></span></label>
                                <input type="text" required id="lastName" onBlur={updateForm} onChange={updateForm} className={" leading-none p-3 border-2  mt-4 rounded focus:ring-0 focus:outline-0 focus:border-1"}/>
                            </div>
                        </div>
                        {/*EMAIL*/}
                        <div className="md:flex items-center mt-8">
                            <div className="w-full flex flex-col">
                                <label className="font-semibold leading-none ">* Email <span id="emailMessage" className="form-error-message"></span></label>
                                <input type="email" required id="email" onBlur={updateForm} onChange={updateForm} className={" leading-none p-3 border-2 mt-4 rounded focus:ring-0 focus:outline-0 focus:border-1"}/>
                            </div>    
                        </div>
                        {/*PHONE*/}
                        <div className="md:flex items-center mt-12">
                            <div className="w-full md:w-1/2 flex flex-col">
                                <label className="font-semibold leading-none ">* Brand/Service Name <span id="brandMessage" className="form-error-message"></span></label>
                                <input type="text" id="brand" required onBlur={updateForm} onChange={updateForm} className={" leading-none p-3 border-2 mt-4 rounded focus:ring-0 focus:outline-0 focus:border-1"} />
                            </div>
                            <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                                <label className="font-semibold leading-none ">Phone <span id="phoneMessage" className="form-error-message"></span></label>
                                <input type="text" id="phone" onBlur={updateForm} onChange={updateForm} className={" leading-none p-3 border-2  mt-4 rounded focus:ring-0 focus:outline-0 focus:border-1"}/>
                            </div>
                        </div>
            
                        <div className="w-full flex flex-col mt-8">
                            <label className="font-semibold leading-none ">Message <span id="messageMessage" className="form-error-message"></span></label>
                            <textarea type="text" id="message" onBlur={updateForm} onChange={updateForm} className={" h-40 text-base border-blue-100 leading-none p-3 mt-4 border-2 rounded focus:ring-0 focus:outline-1 focus:border-1"}></textarea>
                        </div>
                        <div className="flex w-full">
                        <button id="submitButton" className={"bg-blue-700 text-white mt-9 font-semibold leading-none py-4 px-10 rounded"} disabled={true}>
                            SEND
                        </button>
                    </div>
                    </form>
                </div>
            </div>
            {/* END FORM SECTION */}
            {/* CONNECTION POINTS SECTION */}
            <div className="mt-0 ">
            <div className="ml-2 mt-3 font-thin text-4xl text-gray-400 p-4">CONNECTION POINTS</div>
             <div className="w-[auto] sm:max-w-[98%] md:max-w-[99%] ml-1 grid grid-cols-2 sm:grid-cols-3 sm:gap-3  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 p-4">
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-bakery.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-bakery.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-end-cap.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-end-cap.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-entrance.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-entrance.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-haba-end-cap-small.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-haba-end-cap-small.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-haba-end-cap.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-haba-end-cap.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-meat-bunker-cherry.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-meat-bunker-cherry.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-meat-bunker-mason.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-meat-bunker-mason.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-pallet-end-cap.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-pallet-end-cap.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-produce-bunker.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-produce-bunker.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-rx-waiting.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-rx-waiting.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-standee.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-standee.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg m-2 p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-seasonal-hanging.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-seasonal-hanging.png" alt="Nature" className="responsive-image-grid"></img></div>
                <div className="rounded-lg cursor-pointer shadow-lg p-2 hover:bg-blue-200 responsive-image" onClick={()=> {showModal("cp-wine-end-cap.png")}}><img src="https://assets.barrowsconnectedstore.com/retailers/kroger/cp-wine-end-cap.png" alt="Nature" className="responsive-image-grid"></img></div>
            </div>
         
            </div>
            {/* END CONNECTION POINTS SECTION */}
            <div className="w-[90%] text-xs mt-6 pb-3 mx-auto text-center ">
            Copyright reserved © {currentYear}. Confidential intellectual property of Barrows North America Ltd.
</div>
      </div>
    )
}
export default KrogerPOC


/*
 <Toaster 
                toasterShow={toasterShow} 
                toasterColor={toasterColor}
                toasterText={toasterText}
                toasterId="krogerPOC"
                toasterOnClose={toasterCloseHandler}  
                toasterIcon={toasterIcon}
            ></Toaster>
*/