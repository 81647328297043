import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DropDown(props) {
  
  const [initItem, setInitItem] = useState(props.initialItem ? props.initialItem : "Choose One")
  const items = props.items ? props.items : console.error("Dropdown missing callback");
  const callback = props.callback ? props.callback : console.warn("Dropdown missing callback");
  

  //Classes
  const formControlContainerClasses = props.formControlContainerClasses ? props.formControlContainerClasses + " relative inline-block text-left" : "relative inline-block text-left";
  const formControlClasses = props.formControlClasses ? props.formControlClasses + " inline-flex w-full  gap-x-1.5 px-3 py-2 text-sm font-semibold " : "inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50";
  const formControlChevron = props.formControlChevron ? props.formControlChevron : "-mr-1 h-5 w-5 text-gray-400";
  const formControlMenu = props.formControlMenu ? props.formControlMenu + " absolute z-[9999999] left-0 z-10 mt-2 w-56 origin-top-right shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pointer-cursor" : "absolute z-[9999999] left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pointer-cursor";
  const formControlMenuTextOver = props.formControlMenuTextOver ? props.formControlMenuTextOver : "bg-gray-100 text-gray-900"
  const formControlMenuText = props.formControlMenuText ? props.formControlMenuText + " block px-4 py-2 text-sm cursor-pointer" : 'text-gray-700 block px-4 py-2 text-sm cursor-pointer';
  
  const handleMouseOut = () => {
    console.log("handleMouseOUt");
  }
  
  return (
    <>
    {items != null ? 
    <Menu as="div" className={formControlContainerClasses}  >
      <div >
        <Menu.Button className={formControlClasses} >
          {initItem}
          <ChevronDownIcon className={formControlChevron} aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >

      
        
        <Menu.Items className={formControlMenu} >
          <div className="py-1 max-h-[200px] overflow-scroll">
           
            {items.map((item) =>  {
                return(
                    <Menu.Item key={item.id}>
                    {({ active }) => (
                    <a
                        
                        onClick={() => {
                         
                          setInitItem(item.title ? item.title : item.name);
                          callback({"id":item.id,"title":item.title ? item.title : item.name});
                        }}

                        className={classNames(
                        active ? formControlMenuTextOver : formControlMenuText,
                        'block px-4 py-2 text-sm cursor-pointer'
                        )}
                        
                    >
                        {item.title != null ? item.title : item.name}
                    </a>
                    )}
                    </Menu.Item>
                )
            })}
          </div>
        </Menu.Items>
       
      </Transition>
    </Menu>
    : <div>Missing items array</div>}
    </>
  )
}

