import React from 'react';
import * as ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { Controls } from '@vidstack/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
     <BrowserRouter>
       <React.StrictMode>
            <App />
       </React.StrictMode>
      </BrowserRouter>
);

reportWebVitals();
