import React, { useState, useEffect, useRef } from "react";
import {motion, AnimatePresence, useInView, useAnimation} from "framer-motion";
import { HashLink as Link } from 'react-router-hash-link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive'
import ReactFullpage from "@fullpage/react-fullpage";

/* Custom imports */
import Toaster from "../../../controls/Toaster.js";
import SideBarNav from "../../../controls/SideBarNav/SideBarNav";
import DropDown from "../../../controls/DropDown"

/* MUI imports */
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import ReactGA from "react-ga4";
import Footer from '../Footer/Footer.js';
import './home.css';
const Home = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 750px)'
      })
    //START GOOGLE ANALYTICS 
    useEffect(() => {
        ReactGA.send(
            {
                hitType: "pageview",
                page:"/",
                title:"Home",
            }
        )
    }, [])
    //END GOOGLE ANALYTICS
   

   
    const body = document.getElementsByTagName('Body')[0];
    body.style.backgroundColor = "#000000";

    /* Contstants */
    const SIGNATURE = "Barrows Connected Store Home Page :: @V2.1.0.0 :: "
    const headerVideoSRC = "https://assets.barrowsconnectedstore.com/home/videos/cs-multi-retailer-fly-through-mix.mp4";
    const imageDirectory = "https://assets.barrowsconnectedstore.com/home/images/";

    //Styles
    const tailwindStyles = {
        aboutHeadline: "font-bold text-xl md:text-4xl lg:text-6xl lg:mb-4",
        aboutBody: "p-6 pt-0 text-sm lg:text-xl  leading-6 md:leading-8 lg:leading-10"
    }

    // Gallery
    const trackImageLoadProgress = (url) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob'; // Ensures the response is a binary large object, not just plain text
      
        xhr.onprogress = function(event) {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            console.log(`Loading: ${percentComplete.toFixed(2)}%`);
          } else {
            console.log('Loading in progress...');
          }
        };
      
        xhr.onload = function() {
          if (xhr.status === 200) {
            const imgURL = URL.createObjectURL(xhr.response);
            console.log('Image fully loaded');
            
            // Optionally, you can append the loaded image to the document
            const img = document.createElement('img');
            img.src = imgURL;
            document.body.appendChild(img); // Appends image to the body after loading
      
            // Alert when the image is fully loaded
            alert('Image loaded!');
          } else {
            console.error('Error loading image:', xhr.statusText);
          }
        };
      
        xhr.onerror = function() {
          console.error('Failed to load image.');
        };
      
        xhr.send();
      }
    const [selectedImage, setSelectedImage] = useState(false);
    const setGalleryImageLoaded = (index) => {

        
        const img = document.querySelector("#csHomeGallery_" + index);
        const cont = document.querySelector("#csHomeGalleryContainer_" + index);

        if(img && img.style.visibility === "hidden") {
            img.style.visibility = "visible";
        }
        if(cont && cont.classList.contains("hmg-blur-background")) {
            cont.classList.remove("hmg-blur-background");
        }
        //img.style.visibility = "visible";
    }

    /* This section controls the color highligthing effect */
    let [campaignSectionAnimating, setCampaignSectionAnimating] = useState(false);

    const [backgroundHighlightColor, setBackgroundHighlightColor] = useState("barrows");
    const [textHighlightColor, setTextHighlightColor]  = useState("barrows-text");
    const [dotTextureHighlightColor, setDotTextureHighlightColor] = useState("barrows-dot");
    const [brandHighlight, setBrandHighlight] = useState("barrows-border-color");
    
    const [counter, setCounter] = useState(0);
    const WalmartCuePoint = 8;
    const TescoCuePoint = 20;
    const ShopRiteCuePoint = 36;
    
    const brandLogoSection = useRef(null)
    const brandLogoSectionInView = useInView(brandLogoSection);
    const brandLogoAnimation = useAnimation();
    
    const ref2 = useRef(null);
    const sectionTwoInView = useInView(ref2);
    const sectionTwoAnimation = useAnimation();

    const retailMediaSection = useRef(null);
    const retailMediaSectionInView = useInView(retailMediaSection);
    const retailMediaSectionAnimation = useAnimation();

    const campaignMetricsRef = useRef(null);
    const campaignMetricsInView = useRef(campaignMetricsRef, true);
    const campaignMetricsSectionAnimation = useAnimation();

    const contactUsSection = useRef(null);
    const contactUsSectionInView = useInView(contactUsSection);
    const contactUsSectionAnimation = useAnimation()

    const [showFullConnectText, setShowFullConnectText] = useState(false);
    const [showFullCPText, setShowFullCPText] = useState(false);
    const [showFullInsightsText, setShowFullInsightsText] = useState(false);

    const expandContent = (content, state) => {
    
        switch(content) {
            case "connect":
                if(!showFullConnectText && state === true) {
                    setShowFullConnectText(state);
                } else if(showFullConnectText && state === false) {
                    setShowFullConnectText(state);
                }
                break;
            case "connectionPoints":
                if(!showFullCPText && state === true) {
                    setShowFullCPText(state);
                } else if(showFullCPText && state === false) {
                    setShowFullCPText(state)
                }
                break;
            case "connectedInsights":
                if(!showFullInsightsText && state === true) {
                    setShowFullInsightsText(state);
                } else if(showFullInsightsText && state === false) {
                    setShowFullInsightsText(state);
                }
                break;
            default:
                
        }
        
    }
    //Number counters
    let [campaigns, setCampaigns] = useState(0);
    let [networkDisplays, setNetworkDisplays] = useState(10);
    let [networkStores, setNetworkStores] = useState(1);
    const [totalCampaigns, setTotalCampaigns] = React.useState(60);

    const [headlineOne, setHeadlineOne] = useState("Digital Media");
    const [headlineTwo, setHeadlineTwo] = useState("And Merchandising");
    const [headlineThree, setHeadlineThree] = useState("Platform");
    const [reanimateHeadline, setReanimateHeadline] = useState(0);

    useEffect(() => {

        const video = document.querySelector("#csHomeVideo");
        
        const videoIsPlaying = (video) => {
            return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
        };

        //Manages highlight elements
        const highlightInt = () => {
            //console.log("videoIsPlaying", videoIsPlaying(video));
            if(!!videoIsPlaying) {
                //console.log("videoIsntPlaying")
                //video.currentTime = counter;
                //console.log("video.currentTime", video.currentTime, counter);
                //video.play();
            }
            if(counter < 55) {
                setCounter(state => state + 1);
                if(counter < WalmartCuePoint) {
                    //console.log("Barrows");
                 }
         
                 if(counter >= WalmartCuePoint && counter < TescoCuePoint) {
                     setBackgroundHighlightColor("walmart");
                     setTextHighlightColor("walmart-text");
                     setDotTextureHighlightColor("walmart-dot");
                     setBrandHighlight("walmart-border-color");
                     setHeadlineOne("Built For")
                     setHeadlineTwo("Retail Media")
                     setHeadlineThree("2.0")
                     setReanimateHeadline(1);
                 }
         
                 if(counter >= TescoCuePoint && counter < ShopRiteCuePoint) {
                     setBackgroundHighlightColor("tesco");
                     setTextHighlightColor("tesco-text");
                     setDotTextureHighlightColor("tesco-dot");
                     setBrandHighlight("tesco-border-color");
                     setHeadlineOne("Beautifully ");
                     setHeadlineTwo("Designed Digital");
                     setHeadlineThree("Displays");
                     setReanimateHeadline(2);

                 }
         
                 if(counter >= ShopRiteCuePoint) {
                     setBackgroundHighlightColor("shoprite");
                     setTextHighlightColor("shoprite-text");
                     setDotTextureHighlightColor("shoprite-dot");
                     setBrandHighlight("shoprite-border-color")
                     setHeadlineOne("Advanced Ad");
                     setHeadlineTwo("And Analytics");
                     setHeadlineThree("Tech");
                     setReanimateHeadline(3);
                 }
            } else {
                setCounter(state => 0);
            }
            
        }
       
        /* END HIGHLIGHT CONTENT SECTION */

        /* brandLogoSectionInView */
        if(brandLogoSectionInView) {
         
            //console.log(lg({"sig":SIGNATURE, "message":"brandLogoSectionInView"}))
            brandLogoAnimation.start("visible");
        }

        /* ANIMATE WHEN IN VIEW SECTION */
   

        if(campaignMetricsInView) {
       
          
            
            if(campaignSectionAnimating === false) {
               
                setCampaignSectionAnimating(true);
                

            }
            
        } else {
            
            setCampaignSectionAnimating(false);
           
        }

        if(sectionTwoInView) {
            sectionTwoAnimation.start("visible");
        }
        /* END ANIMATE WHEN IN VIEW SECTION */


        if(retailMediaSectionInView) {
            retailMediaSectionAnimation.start("visible");
        }

        if(contactUsSectionInView) {
            contactUsSectionAnimation.start("visible");
        }
        /* CALL FUNCTIONS THAT NEED TO UPDATE ON INT */
        const countInt = setInterval(highlightInt, 1000);

        /* CLEAR COUNT INTERVAL */
        return () => {
          clearInterval(countInt)
        }

      }, [
        counter, 
        brandLogoSectionInView, 
        campaignMetricsInView,
        sectionTwoInView,
        retailMediaSectionInView,
        contactUsSectionInView
    ]);


    /* Logger */
    const lg = (message) =>{
        let log = true;
        if(log) {
            return message;
        }
    };
    
    /* Side Bar Navigation */
    const [showSideBar, setShowSideBar] = useState(false);
    const toggleSideBar = () => {
        console.log(lg({"sig":SIGNATURE, "function":"toggleSideBar"}));
        if(showSideBar === true) {
            setShowSideBar(false);
        } else {
            setShowSideBar(true);
        }
    }
    /* End Side Bar Navigation show/hide */

    /* Contact form */
   
    //Toaster
    const [toasterShow, setToasterShow] = useState(false);
    const [toasterColor, setToasterColor] = useState("bg-green-500");
    const [toasterText, setToasterText] = useState("");
    const [toasterIcon, setToasterIcon] = useState("checkCircle");

    const toasterCloseHandler = () => {
        console.log(lg({"sig":SIGNATURE, "function":"toasterCloseHandler"}));
        setToasterShow(false)
        console.log(lg({"sig":SIGNATURE, "function":"toasterCloseHandler", "showToaster":toasterShow}));
    }

    useEffect(() => {
        if(API_URL === API_DEV) {
            setToasterText("Caution! You are using the DEV API");
            setToasterIcon("warning")
            setToasterColor("bg-yellow-500");
            setToasterShow(true);
        }
    }, [])

    useEffect(()=>{
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const view = queryParams.get("view");

        if(view) {
            document.getElementById(view)?.scrollIntoView();
        }

    }, []);

    const [apiToastNotification, setAPIToastNotification] = useState(false);

    //HOOKS
    const SEND_MAIL = "send-mail";
    const API_DEV = "http://127.0.0.1:5000/";
    const API_PROD = "https://api.barrowsconnectedstore.com/"
    let API_URL = API_PROD;


    if(window.location.href === 'http://localhost:3000/') {
        API_URL = API_DEV;
    }
    //API hooks
    const API = (hook, payload) => {
        //let toasterWrapper = document.querySelector("#toasterWrapper");
        //let toaster = document.querySelector("#toasterComponent");
        switch(hook) {
            case SEND_MAIL:
                    axios.post(API_URL + SEND_MAIL, payload)
                    .then(res => {
                        const api_response_object = res.data;
                        console.log(lg({"sig":SIGNATURE, "function":"API-SEND_MAIL", "endpoint": API_URL, "response_object":JSON.stringify(api_response_object)}));
                        
                        switch(Number(api_response_object.status_code)) {
                            case 202:
                                    //setToasterColor("bg-green-600");
                                    //toaster.innerHTML = "Your inquiry was successfully sent."
                                    setToasterText("Your email was successfully sent.");
                                    setToasterColor("bg-green-600");
                                    setToasterIcon("thumbUp");
                                    setToasterShow(true);
                                break;
                            case 500:
                                    //setToasterColor("bg-red-600");
                                    //toaster.innerHTML = "Your inquiry failed.  Please try again."
                                    setToasterText("Your inquiry failed.  Please try again.");
                                    setToasterColor("bg-red-600");
                                    setToasterIcon("error");
                                    setToasterShow(true);
                                break;
                            default:
                                console.error(lg({"sig":SIGNATURE, "function":"API-SEND_MAIL", "message":"Status code missing from API response"}));
                        
                        }
                        
                        //Animate the toaster in
                        //toasterWrapper.classList.remove("toaster-out");
                        //toasterWrapper.classList.add("toaster-in");
                        //setTimeout(()=>{toasterWrapper.style.opacity = 1}, 2000);

                    })
                break;
            default:
                console.error("No hook passed in API call");
        }   
    }
    //Form initial items
    const [regionInitialItem, setRegionInitialItem] = useState("Choose one");
    const [industryInitialItem, setIndustryInitialItem] = useState("Choose one");

    //Form data
    const [formData, setFormData] = useState({
        firstName:{
            value:"",
            required:true
        },
        lastName: {
            value:"",
            required:true
        },
        email:{
            value:"",
            required:true
        },
        region:{
            value:"",
            required:false
        },
        regionId: {
            value:"",
            required:false
        },
        industry:{
            value:"",
            required:false
        },
        message:{
            value:"",
            required:false
        }
    })

    //Form validation
    const validateEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(email)
    };

    //Manages setting industry for the form
    const SetContactFormIndustry = (props) => {
        //console.log(lg({"sig":SIGNATURE, "function":"SetContactFormIndustry", "props":props}));
        let tmpData = formData;
        tmpData.industry.value = props.title;
        setFormData(tmpData);
    }

    const SetContactFormRegion = (props) => {
        //console.log(lg({"sig":SIGNATURE, "function":"SetContactFormRegion", "props":props}));
        let tmpData = formData;
        tmpData.region.value = props.title;
        tmpData.regionId.value = props.id;
        setFormData(tmpData);

    }

    const validateForm = (event) => {

        //console.log(lg({"sig":SIGNATURE, "function":"validateForm", "payload":event}));

        let valid = false;
        let elm = document.querySelector("#"+event.target.id);
        let elmRequired = event.target.required;
        let messageId = event.target.id + "Message";
        let elmMessage = document.querySelector("#"+messageId);
        
        try {
                //Make sure required values are not empty
                if(event.target.value === '' && elmRequired) {
                            
                    elmMessage.innerHTML = "* This is a required field";
                    elm.classList.add('form-error-highlight');
                    elm.focus();

                } else {

                    //Need to further validate email
                    if(event.target.id === 'email') {
                    
                        if(validateEmail(event.target.value)) {
                            try {
                                elmMessage.innerHTML = "";
                                elm.classList.remove('form-error-highlight');
                            } catch(e) {
                                //console.log(e)
                            }
                            formData.email.value = event.target.value;
                            valid = true;

                        } else {

                            elmMessage.innerHTML = "* Make sure you add a valid email";
                            elm.classList.add('form-error-highlight');
                            elm.focus();

                            
                        }

                    } else {
                        //If not empty make sure the highlight is cleared
                        elmMessage.innerHTML = "";
                        elm.classList.remove('form-error-highlight');
                        valid = true;
                    }
                
                }
                    let enableSubmitButton = true;
                    for (const [ky, val] of Object.entries(formData)) {
                        //console.log(lg({"sig":SIGNATURE, "function":"validateForm", "objectEntries":`${ky}: ${val.value} - ${val.required}`}));
                        //We only store the value if the value is valid.  So, we just need to check if all required fields are populate
                        if(val.value === '' && val.required === true) {
                            //console.log(ky + " was required and not populated")
                            enableSubmitButton = false;
                        }
                    }
                    if(enableSubmitButton) {
                        //console.log("ALL CLEAR!!!!")
                        document.querySelector("#submitButton").enabled = true;
                    }

        } catch(e) {
            console.error(lg({"sig":SIGNATURE, "function":"validateForm", "error":e}));
            
        }
    
        return {"valid": valid, "id":event.target.id, "value": event.target.value};

    }

    const updateForm = (event) => {
        
        //console.log(lg({"sig":SIGNATURE, "function":"updateForm", "payload":event}));
        
        let validateObj = validateForm(event);
        if(validateObj.valid) {
            let tmpData = formData;
            tmpData[event.target.id]["value"] = event.target.value;
            setFormData(tmpData);
            
            //console.log(lg({"sig":SIGNATURE, "function":"formData", "payload": JSON.stringify(formData)}));
        }
        
        //See if all the required fields are populated
    
    }

    const submitForm = (event) => {
        
        event.preventDefault();
        
        //Passes a Google tracking event on each form submission
        ReactGA.event({category:"Contact", action:"form-submitted", label:"Contact form was submitted"});

        let payload = {};

            payload.template = "home";
            payload.firstName = formData.firstName.value;
            payload.lastName = formData.lastName.value;
            payload.email = formData.email.value;
            payload.region = formData.region.value;
            payload.regionId = formData.region.id;
            payload.industry = formData.industry.value;
            payload.message = formData.message.value;

            API(SEND_MAIL, payload);
            document.querySelector("#contactForm").reset()
            setRegionInitialItem("Choose one");
            setIndustryInitialItem("Choose one");

    }

    /* End contact form */
    return(
    <>
    <Toaster 
                toasterShow={toasterShow} 
                toasterColor={toasterColor}
                toasterText={toasterText}
                toasterOnClose={toasterCloseHandler}  
                toasterIcon={toasterIcon}
            ></Toaster>
     <SideBarNav 
                showSideBar={showSideBar}
                backgroundColor={backgroundHighlightColor}
                closeSideBar={toggleSideBar} />
    {/* START NAVIGATION SECTION */}
        <div className="bg-slate-800 flex items-center text-white min-h-[50px]" style={{"position":"fixed", "width":"100vw", "zIndex":300}}>
            <div className="menu-icon-container-v2"><IconButton onClick={()=> {toggleSideBar()}} variant="text" style={{color: grey[50]}}><MenuIcon className="menu-icon" /></IconButton></div>
            <div className=""><img className="logo-v2" src={imageDirectory + "logo.png"} alt="Connected Store logo" /></div>
            <div className="grow text-xs md:text-md text-end mr-3"><Link  to="#contact-us">CONTACT US</Link>&nbsp; <span className="header-login">|</span> &nbsp; <a className="header-login" href="https://portal.barrows.tech/auth/login" target="_blank" rel="noreferrer">LOGIN</a></div>
        </div>
    {/* END NAVIGATION SECTION */}
    {/* START MAIN SITE SECTION */}
        <div className="h-screen flex justify-center text-white">
            <div className="grid grid-col-1">
                <div className="bg-black w-screen  relative">
                     {/* FULL PAGE JS SLIDERS */}
                     <ReactFullpage
                        debug
                        navigationTooltips={["First Section","Second Section","Third Section"]} 
                        slidesNavigation={true} 
                        slidesNavPosition="bottom" 
                        controlArrows={false} 
                        licenseKey="MJ88H-Q38AH-UK18I-XJEM7-ZZMON" 
                        error="Syntax error" 
                        render={() => (
                    <ReactFullpage.Wrapper>
                            
                            <div className="section">
                                <div className="intro">
                                    <h1>Navigation bullets</h1>
                                    <p>Create a vertical and horizontal navigation</p>
                                </div>
                            </div>
                            <div className="section">
                                <div className="slide"><h1>Section 2</h1></div>
                                <div className="slide"><h1>Slide 2.2</h1></div>
                                <div className="slide"><h1>Slide 2.3</h1></div>
                            </div>
                        <div className="section" >
                      
                     {/* START VIDEO SECTION */}
                     <div className="relative hidden">
                        <div className="grid grid-cols-1 absolute z-40 w-full">
                            <AnimatePresence>
                                <motion.div
                                    key={reanimateHeadline}
                                    initial={{ y: -100, opacity: 0 }}
                                    animate={{ y: 100, opacity: 1}}
                                    exit={{opacity:0}}
                                    transition={{type:"spring", bounce: 0.35, duration:1, delay:3}}
                                    className="absolute"
                                >
                                    <div className="text-2xl ml-10 leading-tight  md:text-6xl lg:text-7xl xl:text-8xl md:mt-50 md:ml-50 lg:mt-50 text-white">
                                            {headlineOne} <br/> {headlineTwo} <br/> <span className={textHighlightColor}>{headlineThree}</span>
                                    </div>
                                </motion.div>
                            </AnimatePresence>
                        </div>
                        <div>
                        <div className="hidden">
                            <div>
                                <div className="callout-box-1-card">
                                    <div className={backgroundHighlightColor +  " callout-box-1-pill"}>&nbsp;</div>
                                        <div className="text-white callout-box-1-content">
                                            Build A <br></br><span className="x-bold-txt">RM 2.0</span> <br></br>Offering
                                        </div>
                                </div>  
                            </div>
                            <div className="bg-blue-200">Two</div>
                            <div className="bg-blue-300">Three</div>
                        </div>
                        </div>
                        <div className="video-container relative z-1">
                            <video id="csHomeVideo"  className="video" width="100%"  autoPlay muted webkit-playsinline="true">
                                <source src={headerVideoSRC} type="video/mp4"/>
                            </video>
                        </div>
                      
                    </div>
                  
                    {/* END VIDEO SECTION */}
                {/*RETAILER LOGO SECTION*/}
                <div id="network" className={dotTextureHighlightColor + " "}>
                <div ref={brandLogoSection } className="relative">
                    {/*SECTION HEADLINE START*/}
                            <div className="headline-hr h-px my-8 bg-gray-100 border-0 dark:bg-gray-700 ml-4 mr-4 relative" ></div>
                            <div className="text-white text-md ml-2 md:text-3xl md:ml-4 mb-2">Select <b>Network</b> Partners And Stats</div>  
                                <motion.div 
                                    variants={{
                                        hidden: { opacity:0, x:100},
                                        visible: { opacity:1, x:0}
                                    }}
                                    initial="hidden"
                                    animate={brandLogoAnimation}
                                    transition={{ duation: 2, delay:0.50}}
                                    className={backgroundHighlightColor + " headline-pill-v2 mt-1 ml-2 relative z-10"}>
                            </motion.div>
                     {/*SECTION HEADLINE END*/}
                             <motion.div 
                            
                                variants={{
                                    hidden: { opacity:0, y:-100},
                                    visible: { opacity:1, y:0}
                                }}
                                initial="hidden"
                                animate={brandLogoAnimation}
                                transition={{ duation: 10, delay:.5}}
                                className="flex align-center justify-center logos-section text-white p-2">
                                    <img src="https://assets.barrowsconnectedstore.com/home/images/logos.png" alt="Retailer logos" />
                            </motion.div>
                        <div ref={campaignMetricsRef} className="partner-metrics flex text-white">
                                <div className="w-[33%]">
                                    <div className="metric-top-number text-white">
                                        {totalCampaigns}K

                                    </div>
                                    <div className="metric-bottom-text">LIVE BRAND MESSAGES</div>
                                </div>
                                <div className="w-[33%]">
                                    <div className="metric-top-number">{networkDisplays}K</div>
                                    <div className="metric-bottom-text">NEWTWORK DISPLAYS</div>
                                </div>
                                <div className="w-[33%]">
                                    <div className="metric-top-number">{networkStores}K</div>
                                    <div className="metric-bottom-text">NETWORK STORES</div>
                                </div>
                        </div>
                    </div>
    </div>
     {/*END RETAILER LOGO SECTION*/}
     {/*PHOTO GALLERY SECTION*/}
     <div>
        {/*SECTION HEADLINE START*/}
        <div id="gallery" className="headline-hr h-px my-8 bg-gray-100 border-0 dark:bg-gray-700 ml-4 mr-4 relative" ></div>
                            <div className="text-white text-md ml-2 md:text-3xl md:ml-4 mb-2">Example <b>Work</b></div>  
                                <motion.div 
                                    variants={{
                                        hidden: { opacity:0, x:100},
                                        visible: { opacity:1, x:0}
                                    }}
                                    initial="hidden"
                                    animate={brandLogoAnimation}
                                    transition={{ duation: 2, delay:0.50}}
                                    className={backgroundHighlightColor + " headline-pill-v2 mt-1 ml-2 relative z-10"}>
                            </motion.div>
                     {/*SECTION HEADLINE END*/}
         <div className="gap-4 grid grid-cols-2 md:gap-6 md:grid-cols-3 md:p-10 p-6">
            {[
                { alt: "Item one", cover: "h-full object-cover md:oject-fit md:h-auto", colSpan: 'col-span-2', srcSet:imageDirectory + "hmg-checkout-header-500.png 500w, " + imageDirectory + "hmg-checkout-header-1000.png 1000w," + imageDirectory + "hmg-checkout-header-2000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-checkout-header-500.png" },
                { alt: "Item eleven", cover: "h-full object-cover", rowSpan: "row-span-2",  srcSet:imageDirectory + "hmg-wag-entrance-standee-2-200.png 500w, " + imageDirectory + "hmg-wag-entrance-standee-2-500.png 1000w," + imageDirectory + "hmg-wag-entrance-standee-2-1200.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-wag-entrance-standee-2-500.png"},
                { alt: "Item three", cover: "h-full object-cover md:oject-fit md:h-auto", srcSet:imageDirectory + "hmg-tesco-in-aisle-200.png 500w, " + imageDirectory + "hmg-tesco-in-aisle-500.png 1000w," + imageDirectory + "hmg-tesco-in-aisle-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-tesco-in-aisle-500.png"},
                { alt: "Item four", cover: "h-full object-cover", srcSet:imageDirectory + "hmg-tesco-fresh-200.png 500w, " + imageDirectory + "hmg-tesco-fresh-500.png 1000w," + imageDirectory + "hmg-tesco-fresh-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-fresh-500.png"},
                { alt: "Item five", srcSet:imageDirectory + "hmg-tesco-goalpost-200.png 500w, " + imageDirectory + "hmg-tesco-goalpost-500.png 1000w," + imageDirectory + "hmg-tesco-goalpost-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-tesco-goalpost-500.png"},
                { alt: "Item six", cover: "h-full object-cover", rowSpan: "row-span-2", srcSet:imageDirectory + "hmg-walmart-baby-end-200.png 500w, " + imageDirectory + "hmg-walmart-baby-end-500.png 1000w," + imageDirectory + "hmg-walmart-baby-end-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-walmart-baby-end-500.png"},
                { alt: "Item seven", srcSet:imageDirectory + "hmg-tesco-express-header-200.png 500w, " + imageDirectory + "hmg-tesco-express-header-500.png 1000w," + imageDirectory + "hmg-tesco-express-header-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-tesco-express-header-500.png"},
                { alt: "Item eight", srcSet:imageDirectory + "hmg-walmart-fashion-200.png 500w, " + imageDirectory + "hmg-walmart-fashion-500.png 1000w," + imageDirectory + "hmg-walmart-fashion-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-walmart-fashion-500.png"},
                { alt: "Item nine", srcSet:imageDirectory + "hmg-walmart-beauty-200.png 500w, " + imageDirectory + "hmg-walmart-beauty-500.png 1000w," + imageDirectory + "hmg-walmart-beauty-1000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-walmart-beauty-500.png"},
                { alt: "Item ten", cover: "h-full object-cover md:oject-fit md:h-auto", colSpan: 'col-span-2', srcSet:imageDirectory + "hmg-wag-entrance-standee-500.png 500w, " + imageDirectory + "hmg-wag-entrance-standee-1000.png 1000w," + imageDirectory + "hmg-wag-entrance-standee-2000.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-wag-entrance-standee-500.png"},
                { alt: "Item two", cover: "h-full object-cover", rowSpan: "row-span-2",  srcSet:imageDirectory + "hmg-t-grocery-end-200.png 500w, " + imageDirectory + "hmg-t-grocery-end-500.png 1000w," + imageDirectory + "hmg-t-grocery-end-1200.png 2000w", sizes:"(max-width: 450px) 500px,(max-width: 1024px) 1000px", src: "hmg-t-grocery-end-500.png"},
            ].map((item, index) => (
                <div id={"csHomeGalleryContainer_" + index} key={index} className={" " + `${selectedImage === index? `col-span-2 md:col-span-3 ${item.colSpan || ''}`  : item.colSpan || ''} ${item.rowSpan || ''}`}>
                    <LazyLoadImage loading="lazy" effect="blur" id={"csHomeGallery_" + index} className={`cursor-pointer ${item.cover || 'w-full'} w-full`}  height="100%" srcSet={item.srcSet} sizes={item.sizes} src={imageDirectory + item.src} alt={item.alt} onClick={() => selectedImage === index ? setSelectedImage(false) : setSelectedImage(index)} />
                </div>
            ))}
        </div>
     </div>
     {/*END PHOTO GALLERY SECTION*/}
     {/*ABOUT SECTION*/}
    <div>
        {/*SECTION HEADLINE START*/}
        <div className="headline-hr h-px my-8 bg-gray-100 border-0 dark:bg-gray-700 ml-4 mr-4 relative" ></div>
                            <div className="text-white text-md ml-2 md:text-3xl md:ml-4 mb-2">Build A <b>RMN 2.0</b> Offering</div>  
                                <motion.div 
                                    variants={{
                                        hidden: { opacity:0, x:100},
                                        visible: { opacity:1, x:0}
                                    }}
                                    initial="hidden"
                                    animate={brandLogoAnimation}
                                    transition={{ duation: 2, delay:0.50}}
                                    className={backgroundHighlightColor + " headline-pill-v2 mt-1 ml-2 relative z-10"}>
                            </motion.div>
                     {/*SECTION HEADLINE END*/}
        <div className=" font-serif p-4 mt-4 mb-4 ">
            <div className="flex flex-row w-full justify-center text-center">
                <div className="max-w-[1300px]">
                    <p className="text-xl leading-relaxed mb-4 md:text-2xl lg:text-4xl"><i>"2024 is the year in which Retail Media 2.0-the next critical phase in retail media's evolution-hits its stride. And it's an era where retail media moves up the funnel and into the store."</i></p>
                    <p className="text-2xl">Andrew Lipsman</p>
                    <p className="text-sm">Media, Ads + Commerce</p>
                </div>
            </div>
        </div>
        <div className="p-10 md:text-2xl md:leading-10 mb-6">
            Connected Store is a suite of tools that help retailers stand-up a best of class in-store digital media and merchandising platform.
        </div>
        {/*MAIN ABOUT CONTENT SECTION */}
                            
            <div id="about" className="grid grid-cols-2 mb-10">

                <div className={tailwindStyles.aboutBody}>
                    {isDesktopOrLaptop && expandContent("connect", true)}
                    <p className={tailwindStyles.aboutHeadline}>01. Connect <span className={textHighlightColor}>OS</span></p>
                    Connect <b>OS</b> is Connected Store's proprietary platform operating system. Connect was designed with retail as {showFullConnectText ? " its only objective. The tool is a collection of apps that solve key points of friction unique to retail and retail media. Media owners can easily package Connection Points into media segments for easy audience targeting and efficient flighting. Media Managers can utilize the campaign tools to efficiently build out programmatic and sponsored campaigns. In store, merchants and associates can leverage the platform to approve content launches, dynamically update content, quickly get unit support and manage inventory issues ensuring an optimal shopping experience. Analysts can measure campaign performance through our Insights dashboards and pull all data into their own internal BI tools through our analytics web hooks." : (<><span>... </span><span className="cursor-pointer" onClick={()=>{expandContent("connect", true)}}><u>expand</u></span></>)}</div>
                <div className="w-full flex justify-center">
                    <div className="w-[400px] md:h-[400px]" style={{"backgroundImage":'url("https://assets.barrowsconnectedstore.com/home/images/connect-os.png")', "backgroundSize":"contain", "backgroundRepeat":"no-repeat", "backgroundPosition":"center top"}}></div>
                </div>
            </div>
            <div className="grid grid-cols-2 mb-10">
                <div className="w-full flex justify-center">
                    <div className="min-h-[200px] md:h-[400px] w-[400px]" style={{"backgroundImage":'url("https://assets.barrowsconnectedstore.com/home/images/connection-point.png")', "backgroundSize":"contain", "backgroundRepeat":"no-repeat", "backgroundPosition":"center top"}}></div>
                </div>   
                <div className={tailwindStyles.aboutBody}>
                    {isDesktopOrLaptop && expandContent("connectionPoints", true)}
                    <p className={tailwindStyles.aboutHeadline}>02. Connection <span className={textHighlightColor}>Points</span></p>
                    Connection <b>Points</b> are custom engineered beautifully crafted digital, physical {showFullCPText ? " and sensorial retail touchpoints. We work hard to design them to weave seamlessly into the retail experience. The result is units that feel native to shopping even when their objective is not specifically connected to product or category merchandising. The cladding, the lighting, the media all work together to help make shoppers more brand aware and fully informed." : (<><span>... </span><span className="cursor-pointer" onClick={()=>{expandContent("connectionPoints", true)}}><u>expand</u></span></>)}</div>
            </div>
            <div className="grid grid-cols-2">
                <div className={tailwindStyles.aboutBody}>
                    {isDesktopOrLaptop && expandContent("connectedInsights", true)}
                    <p className={tailwindStyles.aboutHeadline}>03. Connect <span className={textHighlightColor}>Insights</span></p>
                    Connect <b>OS</b> Connect includes a suite of insights to prove out performance. {showFullInsightsText ? "Operations can leverage the platform to pull proof-of-play logs to demonstrate compliance. Analysts can pull detailed engagement reporting to understand dwell, interaction, and conversion, and support can query tickets to rapidly address Connection Point issues. All insights are available for self-service in the platform or via API to your BI tool of choice." : (<><span>... </span><span className="cursor-pointer" onClick={()=>{expandContent("connectedInsights", true)}}><u>expand</u></span></>)}</div>
                <div className="w-full flex justify-center">
                    <div className="w-[400px] md:h-[400px]" style={{"backgroundImage":'url("https://assets.barrowsconnectedstore.com/home/images/connect-insights.png")', "backgroundSize":"contain", "backgroundRepeat":"no-repeat", "backgroundPosition":"center top"}}></div>
                </div>
            </div>
        {/*END MAIN ABOUT CONTENT SECTION*/}

    </div>
     {/*END ABOUT SECTION*/}
     {/*CONTACT SECTION*/}
    <div id="contact-us">
        {/*SECTION HEADLINE START*/}
        <div className="headline-hr h-px my-8 bg-gray-100 border-0 dark:bg-gray-700 ml-4 mr-4 relative" ></div>
                            <div className="text-white text-md ml-2 md:text-3xl md:ml-4 mb-2">Contact <b>US</b></div>  
                                <motion.div 
                                    variants={{
                                        hidden: { opacity:0, x:100},
                                        visible: { opacity:1, x:0}
                                    }}
                                    initial="hidden"
                                    animate={brandLogoAnimation}
                                    transition={{ duation: 2, delay:0.50}}
                                    className={backgroundHighlightColor + " headline-pill-v2 mt-1 ml-2 relative z-10"}>
                            </motion.div>
                     {/*SECTION HEADLINE END*/}
             <div className="bg-slate-900">
                <div className="contact-form text-white">
                <form id="contactForm"  onSubmit={submitForm}>
                <div className="md:flex items-center mt-12">
                    <div className="w-full md:w-1/2 flex flex-col">
                        <label className="font-semibold leading-none ">* First Name <span className="form-required-message">(Required)</span><span id="firstNameMessage" className="form-error-message"></span></label>
                        <input type="text" id="firstName" required onBlur={updateForm} onChange={updateForm} className={brandHighlight + " leading-none text-gray-50 p-3 border-2 mt-4 form-input-background rounded focus:ring-0 focus:outline-0 focus:border-0"} />
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                        <label className="font-semibold leading-none ">* Last Name <span className="form-required-message">(Required)</span><span id="lastNameMessage" className="form-error-message"></span></label>
                        <input type="text" required id="lastName" onBlur={updateForm} onChange={updateForm} className={brandHighlight + " leading-none text-gray-50 p-3 border-2  mt-4 form-input-background rounded focus:ring-0 focus:outline-0 focus:border-0"}/>
                    </div>
                </div>
                <div className="md:flex items-center mt-8">
                    <div className="w-full flex flex-col">
                        <label className="font-semibold leading-none ">* Email <span className="form-required-message">(Required)</span><span id="emailMessage" className="form-error-message"></span></label>
                        <input type="email" required id="email" onBlur={updateForm} onChange={updateForm} className={brandHighlight + " leading-none text-gray-50 p-3 border-3 mt-4 form-input-background rounded focus:ring-0 focus:outline-0 focus:border-0"}/>
                    </div>
                    
                </div>
                <div className="md:flex items-center mt-8">
                    <div className="w-full flex flex-col mb-5 md:mb-0">
                    
                        <label className="font-semibold leading-none">Region</label>
                        <DropDown 
                            required
                            formControlContainerClasses={brandHighlight + " border-2 mt-4 w-[100%] md:w-[240px]  form-input-background rounded-md h-[43px] p-1"} 
                            formControlClasses="rounded-md relative justify-left w-[230px] text-gray-50 focus:ring-0 focus:outline-0 focus:border-0 "
                            formControlChevron="h-5 absolute right-2 text-white text-sm pointer "
                            formControlMenu="form-input-background rounded-md text-white pointer text-sm "
                            formControlMenuText="text-white "
                            formControlMenuTextOver="bg-gray-200 text-black w-[97%] ml-1 rounded"
                            initialItem={regionInitialItem}
                            items={[
                                {"id":1, "title":"Canada"},
                                {"id":2, "title":"Europe"},
                                {"id":3, "title":"South Africa"},
                                {"id":4, "title":"United Kingdom"},
                                {"id":5, "title":"United States"},
                                {"id":6, "title":"Other"}
                            ]} callback={SetContactFormRegion} />
                    </div>
                    <div className="w-full flex flex-col ">
                        <label className="font-semibold leading-none ">Industry</label>
                        <DropDown 
                            required
                            formControlContainerClasses={brandHighlight + " border-2 mt-4 w-[100%] md:w-[240px]  form-input-background rounded-md h-[43px] p-1"} 
                            formControlClasses="rounded-md relative justify-left w-[230px] text-gray-50 focus:ring-0 focus:outline-0 focus:border-0"
                            formControlChevron="h-5 absolute right-2 text-white text-sm pointer "
                            formControlMenu="form-input-background rounded-md text-white pointer text-sm"
                            formControlMenuText="text-white "
                            formControlMenuTextOver="bg-gray-200 text-black w-[97%] ml-1 rounded"
                            initalItem={industryInitialItem}
                            items={[
                                {"id":1, "title":"Agency"},
                                {"id":2, "title":"Brand"},
                                {"id":3, "title":"Retailer"},
                                {"id":4, "title":"Tech Provider"},
                                {"id":5, "title":"Consumer"}
                            ]} callback={SetContactFormIndustry} />
                    </div>
                </div>
               
                <div>
                    <div className="w-full flex flex-col mt-8">
                        <label className="font-semibold leading-none ">* Message <span className="form-required-message">(Required)</span><span id="messageMessage" className="form-error-message"></span></label>
                        <textarea type="text" required id="message" onBlur={updateForm} onChange={updateForm} className={brandHighlight + " h-40 text-base leading-none text-gray-50 p-3  mt-4 form-input-background border-3 rounded focus:ring-0 focus:outline-0 focus:border-0"}></textarea>
                    </div>
                </div>
                <div className="flex w-full">
                    <button id="submitButton" className={backgroundHighlightColor + " mt-9 font-semibold leading-none text-white py-4 px-10  rounded"}>
                        Contact US
                    </button>
                </div>
            </form>
                </div>
            </div>
            </div>
      {/*END CONTACT SECTION*/}
            {/*START FOOTER SECTION*/}
            <Footer></Footer>
            {/*END FOOTER SECTION*/}
            </div>
            </ReactFullpage.Wrapper>
                        )}
                    />
            </div>

              
 {/* END MAIN SITE SECTION */}
       
            </div>
       
        </div>
   
    </>
    )
}

export default Home;