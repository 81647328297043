import React from "react"; 
import { v4 as uuid } from "uuid";

const Modal = (props) => {
    let src = props.src ? props.src : null;
    let cnt = props.content ? props.content : <div>No content specified</div>;
    let alt = props.alt ? props.alt : "Content in modal";
    let title = props.title ? props.title : null;
    let titleCSS = props.titleCSS ? props.titleCSS : "text-gray-600 dark:text-gray-100 text-3xl font-headline2";
    let showTitleHR = props.showTitleHR ? props.showTitleHR : true;
    let frameCSS = props.frameCSS ? props.frameCSS : " bg-light-100 ";
    let id = props.id ? props.id : "csModal";
    let showModal = props.show ? props.show : false;
    let leftOffset = window.scrollX;
    let leftCSS = leftOffset + "px";
    let topOffset = window.scrollY;
    let topCSS = topOffset + "px";
    let key = props.key ? props.key : "modalKeyShouldBeUnique"
    let callBack = props.callBack ? props.callBack : console.warn("Modal -> callback not defined")

    //Lock the scroll bars when modal is visible
    if(showModal) {
        document.body.style.overflowY = "hidden";
        document.body.style.overflowX = "hidden";
        let modal = document.querySelector("#"+id);
        modal.classList.remove("hidden");
    }
    

    const getUID = () => {
        return uuid();
    }

    const closeModal = (e, closeButton=false) => {
        
        if(closeButton || e.target.id === id) {
            let modal = document.querySelector("#"+id);
            const scrollY = document.body.style.top;
            
            document.body.style.position = '';
            document.body.style.top = '';
            
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            
            document.body.style.overflowY = "visible";
            document.body.style.overflowX = "visible";
            
            modal.classList.add("hidden");
    
            callBack();
        } 

    }

    const resizeModal = () => {
        
        let modalFrame = document.querySelector("#"+id);
            modalFrame.style.width = window.innerWidth;
            modalFrame.style.height = window.innerHeight;
    }
    return(
        <div  onResize={()=>{resizeModal()}} id={id} onClick={(e) => {closeModal(e)}}  className="hidden absolute z-50 flex justify-center items-center w-[100vw] h-[100vh] bg-gray-600 bg-opacity-80" style={{ "top":topCSS, "left":leftCSS}}>
            <div  className={"flex relative " + frameCSS} style={{overflow:"scroll"}}>
                <div className="relative top-0 left-0 w-[100%]  pt-2">
                            {title != null ? <div className={titleCSS != null ? titleCSS : " text-1xl"}>{title}</div>  : ""}
                            {title != null && showTitleHR ? <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 mt-2"></hr> : ""}
                            <div className="h-[100%]"> {src != null ? <img src={src} alt={alt} className="w-[100%] h-[100%] max-w-6xl"/> : cnt}</div>
                </div>
                <div className="absolute top-0 right-0">
                    <button  onClick={(e)=> {closeModal(e, true)}} type="button" className="absolute right-5 top-5 w-7 h-7 text-sm font-semibold rounded-full border border-gray-800 pl-1 text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" >
                        <span className="sr-only">Close</span>
                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                    </button>
                </div>
            </div>
    </div>
    )
}
export default Modal;